import { mapActions } from 'vuex';
import { DEFAULT_TPP_IFRAME_STYLES } from '@/utils/constants/accounting';

export default {
    computed: {
        defaultTPPPaymentConfig() {
            return {
                containerSelector: '#tppWidget',
                tokenMode: false,
                fullName: true,
                zipMode: 'required',
                phoneOption: false,
                styles: {
                    ...DEFAULT_TPP_IFRAME_STYLES,
                },
            };
        },
    },
    methods: {
        ...mapActions({
            setTPPWidgetVisibility: 'accounting/setTPPWidgetVisibility',
        }),

        async generateTPPAccessToken() {
            this.isLoading = true;
            try {
                const { token } = await this.queryHandler('getPaymentToken', 'resident');
                this.openTPPWidget(token);
            } catch (error) {
                this.notifyError(error.message);
                this.isLoading = false;
            }
        },

        openTPPWidget(token) {
            // eslint-disable-next-line
            const triple = new Triple(token);
            const paymentForm = triple.generatePaymentForm({
                ...this.defaultTPPPaymentConfig,
                ...this.customTPPPaymentConfig,
                onError: async data => {
                    await this.processPaymentDataBasedOnStatus(data);
                },
                onSuccess: async data => {
                    await this.processPaymentDataBasedOnStatus(data);
                    this.setTPPWidgetVisibility(false);
                },
            });
            const { iframe } = paymentForm;

            if (iframe) {
                iframe.setAttribute('scrolling', 'no');
                iframe.addEventListener('load', () => {
                    this.isLoading = false;
                    this.setTPPWidgetVisibility(true);
                });
            }
        },
    },
};
