export default {
    data() {
        return {
            availablePaymentMethods: [],
            isNsfLimitReached: false,
        };
    },

    methods: {
        async getAvailablePaymentMethods() {
            this.isLoading = true;
            try {
                const { availablePaymentMethods, isNsfLimitReached } = await this.queryHandler('getAvailablePaymentMethods', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                });
                this.availablePaymentMethods =
                    availablePaymentMethods && availablePaymentMethods.length > 0
                        ? availablePaymentMethods.map(method => {
                            if (method === 'bank account') {
                                return method.split(' ')[0];
                            }
                            return method.replace(/\s+/g, '_');
                        })
                        : [];
                this.isNsfLimitReached = isNsfLimitReached && !availablePaymentMethods?.length;
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
